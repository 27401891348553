import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { Link as RouterLink, useParams, useNavigate, redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { domainUrl } from '../services/globalApi';
import genratePdfErrorHandler from '../../hooks/genratePdfErrorHandler';

function ApiErrorMessage({
  openApiError,
  setOpenApiError,
  errorMessage,
  sessionTimeout,
  sanctioned,
  login,
  setRefershState,
  success,
  sentUrl,
  successTrue,
  setSuccessTrue,
  addSuccess,
  setRefreshComp,
  userTokenExpire,
  genratePdfHandler,
  apiResponseAndCreadentials,
  regen,
  setRegen,
  isRejectedTask,
  handleClose,
  resetValue
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirectToLoginPage = () => {
    dispatch({
      type: 'LOGIN_INFO',
      payload: { data: {} }
    });
    const loginUrl = '/';
    const anchor = document.createElement('a');
    anchor.href = loginUrl;
    anchor.click();
  };
  const myState = useSelector((state) => state?.disburseReducer);
  const myDispatch = useDispatch();
  const handleCloseOne = () => {
    if (errorMessage && errorMessage?.includes('User Not permitted')) {
      setOpenApiError(false);
      navigate('/leads');
    }

    if (success === 'true') {
      setOpenApiError(false);
      navigate(`/cards?u${sentUrl}`);
    }
    if (genratePdfHandler === 'true') {
      setOpenApiError(false);
      genratePdfErrorHandler(
        apiResponseAndCreadentials?.response,
        apiResponseAndCreadentials?.credential
      );
    }
    if (addSuccess === 'success') {
      setOpenApiError(false);
      setRefreshComp(new Date());
    }
    if (sessionTimeout === 'sessiontimeout') {
      window.open(`${domainUrl}`, `_self`);
    }

    if (userTokenExpire === 'true') {
      localStorage.clear();
      sessionStorage.clear();
      redirectToLoginPage();
    }

    if (sanctioned === 'sanctioned') {
      window.location.reload();
    }
    if (resetValue === 'true') {
      localStorage.clear();
      sessionStorage.clear();
      redirectToLoginPage();
    }

    if (isRejectedTask === 'true') {
      handleClose();
    }

    if (login === 'login') {
      setRefershState(true);
    }
    if (myState?.errorMsg !== null) {
      myDispatch({
        type: 'ERROR_MESSAGE',
        payload: { data: null, loader: false }
      });
    } else {
      setOpenApiError(false);
    }
    if (regen === 'regen') {
      setOpenApiError(false);
      navigate(`/login/credit-appraisal?u${sentUrl}`);
    }
  };
  return (
    <Box>
      <Dialog
        open={openApiError}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleCloseOne();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => handleCloseOne(e)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
export default ApiErrorMessage;
