import {
  encryptPayload,
  encryptFormData,
  encryptValue,
  encryptArrayOfObjects,
  encryptFile
} from '../../hooks/encryptFile';
import { getClientId, getDeviceInfo } from '../../utils/getConfigdetails';
import { store } from '../../reducer/store';

import {
  ACTIVE_DEPLOYED_ENV,
  DEPLOYMENT_ENV_TYPE_DEV,
  DEPLOYMENT_ENV_TYPE_PROD,
  DEPLOYMENT_ENV_TYPE_UAT,
  RECOMMENDATION_PYTHON_DEV_API_BASE_URL,
  RECOMMENDATION_PYTHON_PROD_API_BASE_URL,
  RECOMMENDATION_PYTHON_UAT_API_BASE_URL,
  globalAdminUrl,
  globalAdminUrlAdmin,
  dynamicKlassAdminUrl
  // globalAdminUrlKudos
} from './globalApi';

const clientId = getClientId();
const deviceInfo = getDeviceInfo();
const baseURL1c = `https://dmp-api.kalolytic.com`;
// const randomIv = 'K@l0lyT1c@2o#$75';

const statusMessages = {
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  207: 'Multi-Status (WebDAV)',
  208: 'Already Reported (WebDAV)',
  226: 'IM Used',
  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  306: '(Unused)',
  307: 'Temporary Redirect',
  308: 'Permanent Redirect (experimental)',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Request Entity Too Large',
  414: 'Request-URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Requested Range Not Satisfiable',
  417: 'Expectation Failed',
  418: 'I am a teapot (RFC 2324)',
  420: 'Enhance Your Calm (Twitter)',
  422: 'Unprocessable Entity (WebDAV)',
  423: 'Locked (WebDAV)',
  424: 'Failed Dependency (WebDAV)',
  425: 'Reserved for WebDAV',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  444: 'No Response (Nginx)',
  449: 'Retry With (Microsoft)',
  450: 'Blocked by Windows Parental Controls (Microsoft)',
  451: 'Unavailable For Legal Reasons',
  499: 'Client Closed Request (Nginx)',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  506: 'Variant Also Negotiates (Experimental)',
  507: 'Insufficient Storage (WebDAV)',
  508: 'Loop Detected (WebDAV)',
  509: 'Bandwidth Limit Exceeded (Apache)',
  510: 'Not Extended',
  511: 'Network Authentication Required',
  598: 'Network read timeout error',
  599: 'Network connect timeout error'
};

const options = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
  timeZone: 'Asia/Kolkata' // Indian Standard Time (IST)
};

function isSerializedObject(obj) {
  return typeof obj === 'string' && obj.trim().startsWith('{') && obj.trim().endsWith('}');
}

export const CommonApiforJourney = async (
  method,
  endpoint,
  credentials,
  authToken,
  formType,
  apisource,
  reqParam,
  isarray
) => {
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  console.log('endpointlogcommon', endpoint, reqParam);
  if (reqParam !== undefined && reqParam !== null && reqParam !== '') {
    endpoint = `${endpoint}${encryptValue(reqParam, randomIv)}`;
    console.log('endpointlogcommonafer', endpoint, reqParam);
  }

  try {
    const headerData = {};
    const requestBody = {};
    let basePath = '';
    const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
    console.log('userDetails679788', userDetails, formType);
    const userId = userDetails?.token?.userId;
    const currentDate = new Date();
    const formatter = new Intl.DateTimeFormat('en-IN', options);
    const timestamp = Date.now();

    // timestamp_user_id(2 digit of user id)_(auto_generator 4 digit number)_VI_Code(4 digit)
    //  ==>
    const nonBinaryData = {};
    const binaryFormData = new FormData();
    if (formType === 'formdata') {
      // Iterate over form data entries
      for (const [key, value] of credentials.entries()) {
        // Check if the value is binary data
        if (value instanceof File || value instanceof Blob) {
          // If binary data, append it to binaryFormData
          binaryFormData.append(key, value);
        } else {
          // Encrypt non-binary data
          // const encryptedValue = CryptoJS.AES.encrypt(value.toString(), secretKey).toString();
          // Store encrypted value in encryptedNonBinaryData object
          nonBinaryData[key] = value;
        }
      }
      console.log('nonBinaryData', nonBinaryData);
      headerData.Authorization = `Bearer ${authToken}`;
      headerData['client-id'] = getClientId();
      headerData['payload-data'] = encryptPayload(nonBinaryData, randomIv);
      headerData['request-id'] = `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`;
    } else {
      headerData['Content-Type'] = `application/json`;
      headerData.Authorization = `Bearer ${authToken}`;
      headerData['client-id'] = getClientId();
      headerData['request-id'] = `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`;
    }

    if (method === 'GET') {
      requestBody.method = method;
      requestBody.headers = headerData;
    } else {
      console.log('credentials436536', credentials, isSerializedObject(credentials));
      let payload = credentials;
      if (isSerializedObject(credentials) === true) {
        payload = JSON.parse(credentials);
      } else {
        payload =
          formType === 'formdata'
            ? binaryFormData
            : isarray === 'array'
            ? credentials
            : JSON.parse(credentials);
      }
      requestBody.method = method;
      requestBody.headers = headerData;
      requestBody.body =
        formType === 'formdata'
          ? payload
          : Array.isArray(payload)
          ? encryptArrayOfObjects(payload, randomIv)
          : encryptPayload(payload, randomIv);
    }

    if (credentials === 'tokenNotRequired') {
      if (ACTIVE_DEPLOYED_ENV === DEPLOYMENT_ENV_TYPE_PROD) {
        basePath = RECOMMENDATION_PYTHON_PROD_API_BASE_URL;
      } else if (ACTIVE_DEPLOYED_ENV === DEPLOYMENT_ENV_TYPE_UAT) {
        basePath = RECOMMENDATION_PYTHON_UAT_API_BASE_URL;
      } else if (ACTIVE_DEPLOYED_ENV === DEPLOYMENT_ENV_TYPE_DEV) {
        basePath = RECOMMENDATION_PYTHON_DEV_API_BASE_URL;
      }
    } else if (apisource === 'admin') {
      basePath = dynamicKlassAdminUrl().adminUrl;
    } else {
      basePath = dynamicKlassAdminUrl().klassUrl;
    }
    console.log('requestBody3534', requestBody);

    return await fetch(`${basePath}/${endpoint}`, requestBody, randomIv)
      .then((response) => {
        if (response.ok) {
          if (
            response.headers.get('content-type').includes('application/octet-stream') ||
            response.headers.get('content-type').includes('application/zip')
          ) {
            return response.blob();
          } else if (response.headers.get('content-type').includes('text/html')) {
            return response.text();
          } else {
            return response.json();
          }
        } else if (response.status === 403) {
          sessionStorage.clear();
          redirectToLoginPage();
        }
      })
      .then((res) => res);
  } catch (error) {
    return {
      msg: `Server not responding error ${error}`,
      message: `Server not responding error ${error}`
    };
  }
};

export const globalService = {
  // common apis
  AccessLevel: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/access-level/configs`,
      credential,
      token,
      randomNumber
    );
  },
  ReportAccessLevel: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/access-level-report/configs`,
      credential,
      token,
      randomNumber
    );
  },

  MasterListType: (type, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/common/master-list/${encryptValue(type, randomNumber)}`,
      token,
      randomNumber
    );
  },
  AutiFillCIFData: (appId, cifId, cardCode, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/fetch-customer-details/${encryptValue(
        `${appId}/${cifId}/${cardCode}`,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  DeleteCardDataApi: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/offline-v2/delete-card-data`,
      credential,
      token,
      randomNumber
    );
  }
};

export const AgreementService = {
  // Bulk Upload the Prospect
  SaveBankInfo: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/agreement/save-bank-info`,
      credential,
      token,
      randomNumber
    );
  },

  SaveBranchInfo: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/agreement/save-branch-info`,
      credential,
      token,
      randomNumber
    );
  },

  AgreementList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.agreementlistApi(
      `${dynamicKlassAdminUrl().klassUrl}/agreement/list`,
      credential,
      token,
      randomNumber
    );
  },

  SaveCommissionInfo: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/agreement/save-commission-info`,
      credential,
      token,
      randomNumber
    );
  },

  UploadDocument: () => `${dynamicKlassAdminUrl().klassUrl}/agreement/uploadDocument`,

  Edit: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/agreement/edit`,
      credential,
      token,
      randomNumber
    );
  },

  Viewagreement: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.viewagreementpostApi(
      `${dynamicKlassAdminUrl().klassUrl}/agreement/view`,
      credential,
      token,
      randomNumber
    );
  },

  BankListAgreement: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/agreement/bank/list`,
      credential,
      token,
      randomNumber
    );
  },

  DeleteBranchInfo: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.putApi(
      `${dynamicKlassAdminUrl().klassUrl}/agreement/delete-branch-info`,
      credential,
      token,
      randomNumber
    );
  },

  DeleteCommissionInfo: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.putApi(
      `${dynamicKlassAdminUrl().klassUrl}/agreement/delete-commission-info`,
      credential,
      token,
      randomNumber
    );
  },

  CheckBranch: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/agreement/checkBranch`,
      credential,
      token,
      randomNumber
    );
  },

  AgreementBankBranchList: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/agreement/branch/${encryptValue(id, randomNumber)}`,
      token,
      randomNumber
    );
  },

  Terminate: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/agreement/terminate`,
      credential,
      token,
      randomNumber
    );
  },

  LoanMasterList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/company/all-loan-masters/list`,
      credential,
      token,
      randomNumber
    );
  },

  ParentLoanMasters: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/company/parent-loan-masters/list`,
      credential,
      token,
      randomNumber
    );
  }, // Loan types by Company

  SubLoanMasters: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/company/loan-masters/list`,
      credential,
      token,
      randomNumber
    );
  }, // Loan sub-types by Company

  ParentEmpTypeList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/company/parent-emp-types/list`,
      credential,
      token,
      randomNumber
    );
  }, // Employment Types by Company

  SubEmpTypeList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/company/emp-types/list`,
      credential,
      token,
      randomNumber
    );
  }, // Sub Employment Types by Company

  // agreement set-active finish button api
  AgreementSetActive: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/agreement/set-active`,
      credential,
      token,
      randomNumber
    );
  },

  BankFilterDropdownList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/agreement/bank-filter-dropdown/list`,
      credential,
      token,
      randomNumber
    );
  }
};

export const CallCenterService = {
  CallCentreList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/call-center/list`,
      credential,
      token,
      randomNumber
    );
  },

  CallCentreView: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/call-center/view`,
      credential,
      token,
      randomNumber
    );
  },

  CallCentreStatus: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/status/${encryptValue(id, randomNumber)}`,
      token,
      randomNumber
    );
  },

  GetAllAssignableStatuses: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/status/assignable-status/${encryptValue(
        id,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  UpdateStatus: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi3(
      `${dynamicKlassAdminUrl().klassUrl}/status/updateStatus`,
      credential,
      token,
      randomNumber
    );
  },

  GenerateCAMReport: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/cam/generate-cam-report`,
      credential,
      token,
      randomNumber
    );
  },

  CamProgress: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/cam/cam-report/progress`,
      credential,
      token,
      randomNumber
    );
  }

  // AddressType: (token) =>
  //   return useApiServices.getApi(`${dynamicKlassAdminUrl().klassUrl}/common/master-list/ADDRESS_TYPE`, token)
};

export const CamServices = {
  handleDownloadDeviationPrint: (taskID, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-task/task-pdf/${encryptValue(
        taskID,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },
  FetchProposalApproval: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/cam/fetch-proposal-approval`,
      credential,
      token,
      randomNumber
    );
  },

  ClusterHeadApproval: (token, deparmentuser) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/department-user-list/${deparmentuser}`,
      token,
      randomNumber
    );
  },
  KudosLogApi: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/kudos/view-kudos-Api-logs`,
      credential,
      token,
      randomNumber
    );
  },

  CommentSavedDeviation: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/cam/save-deviation`,
      credential,
      token,
      randomNumber
    );
  },

  SignUpload: () => `${dynamicKlassAdminUrl().klassUrl}/cam/save-proposal-approval`,
  AprovedDavition: () => `${dynamicKlassAdminUrl().klassUrl}/cam/save-proposal-approval-v2`,

  CreditTaskList: (id, credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-task/list/${encryptValue(id, randomNumber)}`,
      credential,
      token,
      randomNumber
    );
  },

  CreditTaskAssignedTaskList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-task/assigned-task/list`,
      credential,
      token,
      randomNumber
    );
  },

  CreditTaskCreate: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-task/save`,
      credential,
      token,
      randomNumber
    );
  },
  // http://localhost:8082/kcb-prod-klass-api/logout2

  LogoutUser: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(`${dynamicKlassAdminUrl().klassUrl}/logout2`, token, randomNumber);
  },

  ResetPassword: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/reset-password`,
      credential,
      token,
      randomNumber
    );
  },

  CreditTaskStageList: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-task/stage-list/${encryptValue(id, randomNumber)}`,
      token,
      randomNumber
    );
  },

  GetReportTopicSequence: (applicationId, reportTypeId, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/fetch-topics/${encryptValue(
        `${applicationId}/${reportTypeId}`,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  CreditTaskStageStatusList: (id, stageId, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-task/stage-status-list/${encryptValue(
        `${id}/${stageId}`,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  CreditTaskDlpUsers: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-task/dlpUsers/${encryptValue(id, randomNumber)}`,
      token,
      randomNumber
    );
  },
  CreditTaskDevationUsers: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-task/deviationUsers/${encryptValue(
        id,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  CreditTaskHistory: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-task/history/${encryptValue(id, randomNumber)}`,
      token,
      randomNumber
    );
  },

  CreditTaskPostSanctionDlpUsers: (id, token, taskType) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${
        dynamicKlassAdminUrl().klassUrl
      }/credit-task/dlpUsers/post-sanction/${taskType}/${encryptValue(id, randomNumber)}`,
      token,
      randomNumber
    );
  },

  ApplicationTaskType: (token, type) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    const url =
      type === 'audit' || type === 'rating' || type === 'legal' || type === 'valuation'
        ? 'application-task-type'
        : type === 'task'
        ? 'POST_SANCTION_TASK_TYPE'
        : 'application-task-type';
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/common/master-list/${url}/los-partner`,
      token,
      randomNumber
    );
  },
  DeviationTaskType: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/common/master-list/deviation-task-status/los-partner`,
      token,
      randomNumber
    );
  },
  LegalTaskType: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${
        dynamicKlassAdminUrl().klassUrl
      }/common/master-list/LEGAL_PRE_SANCTION_TASK_STATUS/los-partner`,
      token,
      randomNumber
    );
  },
  ApplicationTaskPriority: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/common/master-list/application-task-priority/los-partner`,
      token,
      randomNumber
    );
  },
  ApplicationTaskStatus: (token, type) => {
    const url =
      type === 'audit' || type === 'rating' || type === 'legal' || type === 'valuation'
        ? 'application-task-status'
        : type === 'task'
        ? 'POST_SANCTION_TASK_STATUS'
        : 'application-task-status';
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/common/master-list/${url}/los-partner`,
      token,
      randomNumber
    );
  },
  CreditTaskView: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-task/view/${encryptValue(id, randomNumber)}`,
      token,
      randomNumber
    );
  },

  FetchCamNotes: (id, token, mappIds) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi1(
      `${dynamicKlassAdminUrl().klassUrl}/cam/fetchCamNotes/${encryptValue(id, randomNumber)}`,
      token,
      mappIds,
      randomNumber
    );
  },

  AttachmentCreate: (id, randomIv) =>
    `${dynamicKlassAdminUrl().klassUrl}/credit-task/attachment/create/${encryptValue(
      id,
      randomIv
    )}`,

  AttachmentList: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-task/attachment/list/${encryptValue(
        id,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  AttachmentView: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-task/attachment/view/${encryptValue(
        id,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  ActivityList: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-task/activity/list/${encryptValue(
        id,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  ActivitySave: (id, randomIv) => `${dynamicKlassAdminUrl().klassUrl}/credit-task/activity/save`,

  ActivityDelete: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-task/activity/delete/${encryptValue(
        id,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  WatcherCreate: (id, credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-task/watcher/create/${encryptValue(
        id,
        randomNumber
      )}`,
      credential,
      token,
      randomNumber
    );
  },

  WatcherDelete: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-task/watcher/delete`,
      credential,
      token,
      randomNumber
    );
  },

  WatcherDeleteByTaskId: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-task/watcher/delete-by-task-id/${encryptValue(
        id,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  UsersList: (credential, token, userId, appId) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/my-branch-users/${encryptValue(
        `${userId}/${appId}`,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },
  BranchUser: (credential, token, path) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-task/app-branch-users/${encryptValue(
        path,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  GetDocumentById: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/offline-v2/get-document-by-id`,
      credential,
      token,
      randomNumber
    );
  },

  ScorecardList: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/scorecard/matrix/${encryptValue(id, randomNumber)}`,
      token,
      randomNumber
    );
  },

  FetchScore: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-appraisal/fetch-score/${encryptValue(
        id,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  InternalCreditScore: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-appraisal/internal-credit-score/${encryptValue(
        id,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  }
};

export const AuditServices = {
  FetchAuditList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/audit/grid`,
      credential,
      token,
      randomNumber
    );
  },
  DepartmentUserList: (moduleName, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/department-user-list/${encryptValue(
        moduleName,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  RatingAssigneeList: (ratingId, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/user-list/${encryptValue(ratingId, randomNumber)}`,
      token,
      randomNumber
    );
  },

  UpdateRatingAssignee: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/assign-rating`,
      credential,
      token,
      randomNumber
    );
  },

  UpdateAssignee: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.putApi(
      `${dynamicKlassAdminUrl().klassUrl}/audit/assigned-application`,
      credential,
      token,
      randomNumber
    );
  },

  FetchReportV2: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/audit/fetch-report-v2`,
      credential,
      token,
      randomNumber
    );
  },

  FetchDecisions: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/audit/fetch-decision`,
      credential,
      token,
      randomNumber
    );
  },

  FetchRatingDecisions: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/fetch-decision`,
      credential,
      token,
      randomNumber
    );
  },

  UpdateAudit: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.putApi(
      `${dynamicKlassAdminUrl().klassUrl}/audit/update`,
      credential,
      token,
      randomNumber
    );
  },
  UpdateComment: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/audit/save-comments`,
      credential,
      token,
      randomNumber
    );
  },

  DownloadAuditPdf: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/doc/pdf/download`,
      credential,
      token,
      randomNumber
    );
  }
};
export const RatingServices = {
  DeleteRatingImage: (token, ratingId, fileName) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/remove-doc/${ratingId}/${fileName}`,
      token,
      randomNumber
    );
  },
  RegenerateDecision: (ratingId, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/generate-score/${encryptValue(
        ratingId,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },
  FetchRatinApplicationData: (applicationId, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/fetch-rating-for-application/${encryptValue(
        applicationId,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },
  SaveRatingScoreCardType: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.putApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/save-type`,
      credential,
      token,
      randomNumber
    );
  },
  InternalRatingCreditScore: (ratingId, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/internal-credit-score/${encryptValue(
        ratingId,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },
  SaveAndEditFinancialCardData: (credential, token, ratingId) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/save-financial-data/${ratingId}`,
      credential,
      token,
      randomNumber
    );
  },
  FetchRatingDocumentsList: (token, ratingId) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/get-document/${encryptValue(
        ratingId,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },
  SaveRating: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/save`,
      credential,
      token,
      randomNumber
    );
  },
  FetchRatingListApi: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/grid`,
      credential,
      token,
      randomNumber
    );
  },
  FetchCreditAppraisalV2Rating: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/fetch-financial-data`,
      credential,
      token,
      randomNumber
    );
  },
  FetchDecisionRating: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/fetch-decision`,
      credential,
      token,
      randomNumber
    );
  },
  MapPanOrCifWithRatingId: (token, credential) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/map-rating`,
      credential,
      token,
      randomNumber
    );
  },
  FetchCifPanList: (token, typeId, typeValue) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/fetch-rating/${typeId}/${typeValue}`,
      token,
      randomNumber
    );
  },
  FetchRatingDecisionUserList: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/rating-dept-users`,
      token,
      randomNumber
    );
  },
  FetchCifPanListExisting: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/fetch-rating`,
      credential,
      token,
      randomNumber
    );
  },
  RatingFetchAssigneeList: (ratingId, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/fetch-assignee/${encryptValue(
        ratingId,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },
  UpdateResetDlp: (ratingId, assignee, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/update-assignee/${ratingId}/${assignee}`,
      token,
      randomNumber
    );
  },
  FetchHistory: (token, ratingId) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/get-history/${encryptValue(
        ratingId,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },
  FetchDecisionSaveRating: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/save-decision`,
      credential,
      token,
      randomNumber
    );
  },
  FetchFinancialYear: (token, ratingId) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/get-financial-year/${encryptValue(
        ratingId,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },
  FetchFinancialYearList: (token, ratingId) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/fetch-financial-year-dropdown/${encryptValue(
        ratingId,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },
  UpdateFinancialYear: (token, ratingId, year) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/update-financial-year/${ratingId}/${year}`,
      token,
      randomNumber
    );
  }
};
export const SecuritiesServices = {
  FetchSecurityListApi: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/security-report/grid`,
      credential,
      token,
      randomNumber
    );
  },

  FetchPreSanctionReport: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/security-report/legal/fetch-pre-sanction-report`,
      credential,
      token,
      randomNumber
    );
  },

  FetchBranchList: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/security-report/branch-list`,
      token,
      randomNumber
    );
  },

  FetchLegalDecision: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/security-report/fetch-legal-decisions`,
      credential,
      token,
      randomNumber
    );
  },

  FetchValuationDecision: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/security-report/fetch-valuation-decisions`,
      credential,
      token,
      randomNumber
    );
  },

  FetchDecisionSaveSecurities: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/security-report/save-decisions`,
      credential,
      token,
      randomNumber
    );
  },

  DeleteImage2: (endpoint, authToken, imageUrl) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.putApi3(
      `${dynamicKlassAdminUrl().klassUrl}/security-report/removeImage/${endpoint}`,
      authToken,
      imageUrl,
      randomNumber
    );
  }
};

export const DisbursementService = {
  // Document module
  DisDocumentList: (id, credential, token, isFourWheelerLoan) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      isFourWheelerLoan
        ? `${dynamicKlassAdminUrl().klassUrl}/disb-document/list/${encryptValue(id, randomNumber)}`
        : `${dynamicKlassAdminUrl().klassUrl}/doc/security-doc/list`,
      credential,
      token,
      randomNumber
    );
  },

  DisbDocumentUpload: (id1, id2, randomIv) =>
    `${dynamicKlassAdminUrl().klassUrl}/disb-document/upload/${encryptValue(
      `${id1}/${id2}`,
      randomIv
    )}`,

  DocumentSupportList: (id1, id2, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/disb-document/with-support/list/${encryptValue(
        `${id1}/${id2}`,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  DisbDocumentVerify: (id1, id2, credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/disb-document/verify/${encryptValue(
        `${id1}/${id2}`,
        randomNumber
      )}`,
      credential,
      token,
      randomNumber
    );
  },

  DeleteList: (id1, id2, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/disb-document/delete/${encryptValue(
        `${id1}/${id2}`,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  // Disbursement module
  DeleteImage: (token, applicationId, imageUrl) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.putApi2(
      `${dynamicKlassAdminUrl().klassUrl}/field-visit/removeImage`,
      token,
      applicationId,
      imageUrl,
      randomNumber
    );
  },

  FetchFullDisbursal: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/disbursement/fetchFullDisbursal/${encryptValue(
        id,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  }, // 38 task

  FetchTranchDisbursal: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/disbursement/fetchTranchDisbursal/${encryptValue(
        id,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  }, // 40 task

  SaveFullDisbursal: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/disbursement/saveFullDisbursal`,
      credential,
      token,
      randomNumber
    );
  }, // 39 task

  FetchRelatedParty: (token, appId) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi1(
      `${dynamicKlassAdminUrl().klassUrl}/fetch-related-party/${appId}`,
      token,
      randomNumber
    );
  },

  SaveRelatedParty: (credential, token, appId) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/save-related-party`,
      credential,
      token,
      randomNumber
    );
  },
  CalculateNoOfInstallments: (credential, token, appId) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/calculate-noOfInstallments`,
      credential,
      token,
      randomNumber
    );
  },

  FieldVisitSubmit: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/field-visit/submit`,
      credential,
      token,
      randomNumber
    );
  },

  SaveTranchDisbursal: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/disbursement/saveTranchDisbursal`,
      credential,
      token,
      randomNumber
    );
  }, // 42 task

  SaveMachinery: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/disbursement/saveMachinery`,
      credential,
      token,
      randomNumber
    );
  }, // 43 task

  FetchMachinery: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/disbursement/fetchMachinery/${encryptValue(
        id,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  }, // 44 task
  // Repayment API Services

  DisbursementSaveFormData: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/disbursement/re-payment-save`,
      credential,
      token,
      randomNumber
    );
  },

  DisbursementGetAmortizationDetails: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${
        dynamicKlassAdminUrl().klassUrl
      }/disbursement/re-payment-amortization-schedule/${encryptValue(id, randomNumber)}`,
      token,
      randomNumber
    );
  },

  DisbursementRePaymentDetails: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/disbursement/re-payment-details/${encryptValue(
        id,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },
  // Decision module

  FetchChecklist: (id1, id2, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/disbursement/decision/fetchChecklist/${encryptValue(
        `${id1}/${id2}`,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  SaveChecklistRemarks: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.putApi(
      `${dynamicKlassAdminUrl().klassUrl}/disbursement/decision/saveChecklistRemarks`,
      credential,
      token,
      randomNumber
    );
  },

  RepaymentSaveFormData: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/disbursement/re-payment-pdc-save`,
      credential,
      token,
      randomNumber
    );
  },

  RepaymentPDCChequeUpload: (id, randomIv) =>
    `${dynamicKlassAdminUrl().klassUrl}/disbursement/re-payment-pdc-upload/${encryptValue(
      id,
      randomIv
    )}`
};

export const leadsService = {
  // Leads Submit API (fill details) man 03/06/2022
  LeadsSubmissionMode: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/master/lead-submission-modes`,
      token,
      randomNumber
    );
  },

  SaveScoreCardType: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.putApi(
      `${dynamicKlassAdminUrl().klassUrl}/score-card/save-type`,
      credential,
      token,
      randomNumber
    );
  },
  DetailedSearchbyDIN: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApiExternalApi(
      `${baseURL1c}/detailed_search_by_din`,
      credential,
      token,
      randomNumber
    );
  },
  FetchAllExternalApi: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/external-api-list/${encryptValue(id, randomNumber)}`,
      token,
      randomNumber
    );
  },
  ExternalApidoucmentDownlaod: (id, fileName, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/download-external-api-doc/${encryptValue(
        `${id}/${fileName}`,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },
  DownloadApiIndividuals: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/download-external-history/${encryptValue(
        `${id}`,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },
  SaveAllExternalApiData: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/execute-external-api`,
      credential,
      token,
      randomNumber
    );
  },
  FraudCheckApiList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/execute-fraud-check`,
      credential,
      token,
      randomNumber
    );
  },
  HistoryAllExternalApiData: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/list-history`,
      credential,
      token,
      randomNumber
    );
  },
  SaveUploadFileData: () => `${dynamicKlassAdminUrl().klassUrl}/execute-external-api/file`,
  // SaveUploadFileData: (credential, token) => {
  //   const min = 1000;
  //   const max = 9999;
  //   const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  //   return useApiServices.postApi(
  //     `${dynamicKlassAdminUrl().klassUrl}/execute-external-api/file`,
  //     credential,
  //     token,
  //     randomNumber
  //   );
  // },
  AssetChargesSearch: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApiExternalApi(
      `${baseURL1c}/asset_charges_search_by_cin`,
      credential,
      token,
      randomNumber
    );
  },
  PANtoGSTList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApiExternalApi(
      `${baseURL1c}/pan_to_gstlist`,
      credential,
      token,
      randomNumber
    );
  },
  NegativeDueDiligenceAPI: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApiExternalApi(
      `${baseURL1c}/negative_due_dilligence`,
      credential,
      token,
      randomNumber
    );
  },
  PANVerificationApi: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApiExternalApi(
      `${baseURL1c}/pan_verification`,
      credential,
      token,
      randomNumber
    );
  },
  VehicleRCVerificationAPI: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApiExternalApi(
      `${baseURL1c}/vehicle_rc_verification`,
      credential,
      token,
      randomNumber
    );
  },

  BankBranchList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/bank-branch/list`,
      credential,
      token,
      randomNumber
    );
  },
  DynamicLoanSubType: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/company/publish-loan-masters/list`,
      credential,
      token,
      randomNumber
    );
  },
  DynamicEmploySubType: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/company/publish-emp-sub-masters/list`,
      credential,
      token,
      randomNumber
    );
  },
  AddBankBranch: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/branch/add-branch`,
      credential,
      token,
      randomNumber
    );
  },
  ContactPersonList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/bank/contact-persons`,
      credential,
      token,
      randomNumber
    );
  },
  AddContactPerson: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/bank/add-contact-person`,
      credential,
      token,
      randomNumber
    );
  },
  SubmitApplication: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/leads/submit-application-record`,
      credential,
      token,
      randomNumber
    );
  },
  ListSubmittedApplication: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/leads/list-submitted-application`,
      credential,
      token,
      randomNumber
    );
  },

  // Arundhuti
  // listing of leads
  LeadsList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/lead/list`,
      credential,
      token,
      randomNumber
    );
  },

  // select status in leads page
  ListStageStatus: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/leads/list-stage-statuses`,
      credential,
      token,
      randomNumber
    );
  },

  // select source
  // SourceList: (token) => return useApiServices.getApi(`${dynamicKlassAdminUrl().klassUrl}/prospect-source/list`, token),

  // view application summary
  ApplicationSummary: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/leads/application-summary`,
      credential,
      token,
      randomNumber
    );
  },

  // view leads report
  ViewReport: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/leads/view-report`,
      credential,
      token,
      randomNumber
    );
  },

  // update lead stage
  UpdateStageStatuses: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/leads/update-stage-statuses`,
      credential,
      token,
      randomNumber
    );
  },

  // assign leads
  AssignLeads: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/leads/assign-leads`,
      credential,
      token,
      randomNumber
    );
  },
  AssignLeadsApi: (token, id, fromUserId, toUserId) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.putApi1(
      `${dynamicKlassAdminUrl().klassUrl}/dlp/delegate-dlp-user/${encryptValue(
        `${id}/${fromUserId}/${toUserId}`,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },
  // internal sales manage list
  InternalSalesManager: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/internal-sales-manager/list`,
      credential,
      token,
      randomNumber
    );
  },

  AssigneesList: (token, userId, appId) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/my-branch-users/${encryptValue(
        `${userId}/${appId}`,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  AssignedLeadsList: (token, id) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/dlp/fetch-dlp-assigned-users/${encryptValue(
        id,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  AppointmentsList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/lead/verticalAppointments`,
      credential,
      token,
      randomNumber
    );
  },

  AppointmentsListHorizontal: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/lead/horizontalAppointments`,
      credential,
      token,
      randomNumber
    );
  },
  CreateAppointment: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/lead/createAppointment`,
      credential,
      token,
      randomNumber
    );
  },
  CreateReminder: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/lead/createReminder`,
      credential,
      token,
      randomNumber
    );
  },
  AddComment: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/lead/addComment`,
      credential,
      token,
      randomNumber
    );
  },
  DeleteAppointment: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/lead/deleteAppointment`,
      credential,
      token,
      randomNumber
    );
  },

  SendUrlToLead: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/email/sendUrlToLead`,
      credential,
      token,
      randomNumber
    );
  },
  LeadCloneApplication: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/lead/clone-app`,
      credential,
      token,
      randomNumber
    );
  }
};

export const loginListService = {
  // Leads Submit API (fill details) man 03/06/2022
  LoginList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/log-in/list`,
      credential,
      token,
      randomNumber
    );
  },

  // select status in login page
  ListStageStatusLogin: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/log-in/list-stage-statuses`,
      credential,
      token,
      randomNumber
    );
  },

  // update login stage
  UpdateStageStatusesLogin: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/log-in/update-stage-statuses`,
      credential,
      token,
      randomNumber
    );
  },

  // login decision info
  LoginDecisionInfo: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/log-in/decision-info`,
      credential,
      token,
      randomNumber
    );
  },

  // Processing fee status
  ListProcessingFeeStatuses: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/log-in/list-processing-fee-statuses`,
      token,
      randomNumber
    );
  },

  // Add decision info
  AddDecisionInfo: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/log-in/add-decision-info`,
      credential,
      token,
      randomNumber
    );
  },

  // Rejection list
  ListRejectionStatuses: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/log-in/list-rejection-statuses`,
      token,
      randomNumber
    );
  },

  // Login view report API (fill details) man 08/06/2022
  LoginViewReport: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/log-in/view-reports`,
      credential,
      token,
      randomNumber
    );
  },

  AddRejectedReason: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/log-in/add-rejected-reason`,
      credential,
      token,
      randomNumber
    );
  },

  // saving application summary details
  SaveApplicationDetails: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/log-in/saveApplicationDetails`,
      credential,
      token,
      randomNumber
    );
  },

  // submit login api
  // Listing of application summary details
  LoginListApplicationSummary: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/log-in/list-application-summary`,
      credential,
      token,
      randomNumber
    );
  },
  // lead submission mode
  LeadsSubmissionMode: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/master/lead-submission-modes`,
      token,
      randomNumber
    );
  },

  // bank branch
  BankBranchList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/bank-branch/list`,
      credential,
      token,
      randomNumber
    );
  },

  // applicaiton login type dropdown
  PartnerApplicationLoginType: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/master/partner-application-login-type`,
      token,
      randomNumber
    );
  },

  // login code
  LoginCodesList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/log-in/dsa-login-codes-list`,
      credential,
      token,
      randomNumber
    );
  }

  // LoginList: (token) =>
  // return useApiServices.getApi(`${dynamicKlassAdminUrl().klassUrl}/log-in/list`, token),
  //   BankBranchList: (credential, token) =>
  //     return useApiServices.postApi(`${dynamicKlassAdminUrl().klassUrl}/bank-branch/list`, credential, token),
  //   AddBankBranch: (credential, token) =>
  //     return useApiServices.postApi(`${dynamicKlassAdminUrl().klassUrl}/branch/add-branch`, credential, token),
  //   ContactPersonList: (credential, token) =>
  //     return useApiServices.postApi(`${dynamicKlassAdminUrl().klassUrl}/bank/contact-persons`, credential, token),
  //   AddContactPerson: (credential, token) =>
  //     return useApiServices.postApi(`${dynamicKlassAdminUrl().klassUrl}/bank/add-contact-person`, credential, token),
  //   SubmitApplication: (credential, token) =>
  //     return useApiServices.postApi(
  //       `${dynamicKlassAdminUrl().klassUrl}/leads/submit-application-record`,
  //       credential,
  //       token
  //     ),
  //   ListSubmittedApplication: (credential, token) =>
  //     return useApiServices.postApi(
  //       `${dynamicKlassAdminUrl().klassUrl}/leads/list-submitted-application`,
  //       credential,
  //       token
  //     )
};

export const partnerService = {
  RequestOtp: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/sms-api/request-otp`,
      credential,
      token,
      randomNumber
    );
  },
  VerifyOtp: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/sms-api/verify-otp`,
      credential,
      token,
      randomNumber
    );
  },

  LoginForm: (credential) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postWithoutTokenApi(
      `${dynamicKlassAdminUrl().klassUrl}/login?code=${randomNumber}`,
      credential,
      randomNumber
    );
  },
  ValidateFingerPrint: (credential) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postWithoutTokenApi(
      `${dynamicKlassAdminUrl().klassUrl}/validate-fingerprint`,
      credential,
      randomNumber
    );
  },
  SecondSetupVerify: (credential) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postWithoutTokenApi(
      `${dynamicKlassAdminUrl().klassUrl}/login-otp-generate`,
      credential,
      randomNumber
    );
  },
  SecondSetupVerifyAdmin: (credential) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postWithoutTokenApi(
      `${dynamicKlassAdminUrl().adminUrl}/admin-login-otp-generate`,
      credential,
      randomNumber
    );
  },
  LoginFormA: (credential) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postWithoutTokenApi(
      `${dynamicKlassAdminUrl().klassUrl}/validateUser?code=${randomNumber}`,
      credential,
      randomNumber
    );
  },
  LoginFormAdmin: (credential, randomIv) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postWithoutTokenApi(
      `${dynamicKlassAdminUrl().adminUrl}/letme?code=${randomNumber}`,
      credential,
      randomNumber
    );
  },
  PartnerSignup: (credential) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postWithoutTokenApi(
      `${dynamicKlassAdminUrl().klassUrl}/onboard/partner-signup`,
      credential,
      randomNumber
    );
  },
  RestartpartnerSignup: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.restartPartnerSignupWithTokenApi(
      `${dynamicKlassAdminUrl().klassUrl}/onboard/restart-partner-signup`,
      credential,
      token,
      randomNumber
    );
  },
  // common apis
  OnBoardList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/onboard/list`,
      credential,
      token,
      randomNumber
    );
  },

  SetTokenFromAdmin: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/setToken`,
      credential,
      token,
      randomNumber
    );
  },

  UpdatePartnerStatus: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/onboard/update-partner-status`,
      credential,
      token,
      randomNumber
    );
  },

  OnBoardReview: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/onboard/review`,
      credential,
      token,
      randomNumber
    );
  },

  // On Boarding API
  BankList: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/bank/list`,
      token,
      randomNumber
    );
  },

  LoanTypes: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/master/loan-types`,
      token,
      randomNumber
    );
  },
  // Set commission API

  PartnerCommission: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/onboard/set-commission`,
      credential,
      token,
      randomNumber
    );
  },

  // Show Set commission API
  ShowCommission: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/onboard/list-commission`,
      credential,
      token,
      randomNumber
    );
  },

  // Completed

  // document-types API
  DocumentTypes: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/master/document-types`,
      token,
      randomNumber
    );
  },

  PartnerTypes: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/master/partner-types`,
      token,
      randomNumber
    );
  },

  // state list url
  StateList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/sa/cscs/state/list`,
      credential,
      token,
      randomNumber
    );
  },

  // city list url
  CityList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/sa/cscs/city/list`,
      credential,
      token,
      randomNumber
    );
  },

  // upload document API
  DocumentUpload: (randomIv) => `${dynamicKlassAdminUrl().klassUrl}/onboard/document-upload`,

  // Company Type
  CompanyType: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/master/partner-company-type`,
      token,
      randomNumber
    );
  },

  // create partner api
  CreatePartner: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/onboard/create-partner`,
      credential,
      token,
      randomNumber
    );
  },
  // partner status types
  PartnerStatusTypes: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/master/partner-status-type`,
      token,
      randomNumber
    );
  },

  // select source
  SourceList: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/prospect-source/list`,
      token,
      randomNumber
    );
  },

  // login submit global api

  LeadsSubmissionMode: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/master/lead-submission-modes`,
      token,
      randomNumber
    );
  },
  BankBranchList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/bank-branch/list`,
      credential,
      token,
      randomNumber
    );
  },
  AddBankBranch: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/branch/add-branch`,
      credential,
      token,
      randomNumber
    );
  },
  ContactPersonList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/bank/contact-persons`,
      credential,
      token,
      randomNumber
    );
  },
  AddContactPerson: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/bank/add-contact-person`,
      credential,
      token,
      randomNumber
    );
  }

  // kudos api
  // LoginFormKudos: () => `${globalAdminUrlKudos}/userlogin`
};

export const PayoutService = {
  PayoutAddUpdate: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/payout/add-update`,
      credential,
      token,
      randomNumber
    );
  },

  PayoutListData: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/payout/list`,
      credential,
      token,
      randomNumber
    );
  },

  PayoutViewData: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/payout/view`,
      credential,
      token,
      randomNumber
    );
  },

  PayoutAddComment: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/payout/add-comment`,
      credential,
      token,
      randomNumber
    );
  },

  PayoutListCommentLogs: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/payout/list/comment-logs/${encryptValue(
        id,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  }
};

export const PolicyService = {
  AddNewPolicy: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postpolicyApi(
      `${dynamicKlassAdminUrl().klassUrl}/policy/add-new-policy`,
      credential,
      token,
      randomNumber
    );
  },

  PoliciesList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.policylistApi(
      `${dynamicKlassAdminUrl().klassUrl}/policy/policies-list`,
      credential,
      token,
      randomNumber
    );
  },

  // policy view details
  PolicyViewDetails: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/policy/view-details`,
      credential,
      token,
      randomNumber
    );
  },

  AllEmpMastersList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/company/all-emp-masters/list`,
      credential,
      token,
      randomNumber
    );
  }
};

export const prospectService = {
  // List all propsect
  ProspectListApi: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/prospect/list`,
      credential,
      token,
      randomNumber
    );
  }, // TASK#6, TASK#7, TASK#9  // done api response pending

  // Fetch Prospect Status for filter
  ProspectListStageStatuses: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/prospect/list-stage-statuses`,
      credential,
      token,
      randomNumber
    );
  }, // TASK#8

  // View individual prospect
  ProspectView: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/prospect/view`,
      credential,
      token,
      randomNumber
    );
  }, // TASK#10

  // Delete prospect
  ProspectDelete: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/prospect/delete`,
      credential,
      token,
      randomNumber
    );
  }, // TASK#11

  // Bulk Upload the Prospect
  ProspectBulkUpload: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/prospect/bulk-upload`,
      credential,
      token,
      randomNumber
    );
  }, // TASK#12

  // Download "Bulk Upload" Template
  ProspectDownloadTemplate: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/prospect/download-template`,
      token,
      randomNumber
    );
  }, // TASK#13

  // Save Prospect to database
  ProspectCreate: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/prospect/create`,
      credential,
      token,
      randomNumber
    );
  }, // TASK#5  // done api response pending

  // assign leads
  AssignProspects: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/prospect/assign-prospects`,
      credential,
      token,
      randomNumber
    );
  },

  // Employment Types
  EmploymentTypes: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/master/all-combined-employment-types`,
      token,
      randomNumber
    );
  },

  // Property Configuration Type
  PropertyConfigurationType: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/master/property-configuration-type`,
      token,
      randomNumber
    );
  },

  DownloadProspectTemplate: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.fireApiProspectDownload(
      `${dynamicKlassAdminUrl().klassUrl}/prospect/downloadCSVTemplate`,
      token,
      randomNumber
    );
  },

  ProspectUpload: (randomIv) => `${dynamicKlassAdminUrl().klassUrl}/prospect/upload`,

  AllLoanMastersList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/company/all-loan-masters/list`,
      credential,
      token,
      randomNumber
    );
  }
};

export const RevenueService = {
  // add ne revenue file
  AddNewFile: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/revenue/add-update-file`,
      credential,
      token,
      randomNumber
    );
  },

  RevenueListData: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/revenue/list`,
      credential,
      token,
      randomNumber
    );
  },

  RevenueViewFile: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/revenue/view-file`,
      credential,
      token,
      randomNumber
    );
  },

  UploadMisFile: () => `${dynamicKlassAdminUrl().klassUrl}/revenue/upload-mis-file`,

  DownloadMisTemplate: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.fireApiRevenueDownloadFilePostApi(
      `${dynamicKlassAdminUrl().klassUrl}/revenue/download-mis-template`,
      credential,
      token,
      randomNumber
    );
  },

  AddComment: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/revenue/add-comment`,
      credential,
      token,
      randomNumber
    );
  },

  CommentLogs: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/revenue/list/comment-logs/${encryptValue(
        id,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  ListPartnerDetail: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/revenue/list/partner-detail`,
      token,
      randomNumber
    );
  },

  EditPartnerDetail: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/revenue/edit/partner-detail`,
      credential,
      token,
      randomNumber
    );
  },

  CheckPartnerExist: (id, id1, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/revenue/check-partner-exist/${encryptValue(
        `${id}/${id1}`,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  }
};

export const SanctionedService = {
  // List all propsect

  DisbursementMemo: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/field-visit/disbursement-memo-pdf`,
      credential,
      token,
      randomNumber
    );
  },

  SanctionedLetterList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/doc/sanction-letter-list`,
      credential,
      token,
      randomNumber
    );
  },

  DisbursementMemoDropdownPdf: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/doc/disbursment/download`,
      credential,
      token,
      randomNumber
    );
  },

  FieldVistPrintLetter: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/field-visit/field-visit-pdf`,
      credential,
      token,
      randomNumber
    );
  },

  SanctionGrid: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/sanction/grid`,
      credential,
      token,
      randomNumber
    );
  },

  SanctionLetter: (credential, authToken) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/sanction/get-term-and-conditions?${encryptValue(
        `sanctionType=${credential}`,
        randomNumber
      )}`,
      authToken,
      randomNumber
    );
  },

  StatusSanction: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/status/assignable-status/${encryptValue(
        `${credential}`,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  DownloadDropdownOptions: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/doc/ldoc55-doc-list/${encryptValue(
        `${credential}`,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  }
};

export const UDisburseService = {
  // UDisburse Module

  UDisburseGridData: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/u-disburse/grid`,
      credential,
      token,
      randomNumber
    );
  }
};

export const uSanctionService = {
  // List all propsect
  USanctionGrid: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/u-sanction/grid`,
      credential,
      token,
      randomNumber
    );
  },

  StatusUSanction: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/status/assignable-status/${encryptValue(
        `${credential}`,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  }
};

// admin api starts

// journey
export const AdminService = {
  ExportData: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/export/data`,
      credential,
      token,
      randomNumber
    );
  },
  ExportTansfer: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/export/transfer`,
      credential,
      token,
      randomNumber
    );
  },

  DownloadFiles: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    console.log('credential54543543', credential);
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/export/download`,
      credential,
      token,
      randomNumber
    );
  },

  FetchDocList: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/export/list`,
      token,
      randomNumber
    );
  },

  SaveJourneyTemplate: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/journey/save-journey-as-template`,
      credential,
      token,
      randomNumber
    );
  },

  LoadTemplateDropDown: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/journey/template-name/list`,
      token,
      randomNumber
    );
  },

  SetTokenFromAdmin: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/setToken`,
      token,
      randomNumber
    );
  },

  JourneyTemplateList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/journey/journey-template/list`,
      credential,
      token,
      randomNumber
    );
  },

  JourneyTemplateCopy: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/journey/journey-template/copy`,
      credential,
      token,
      randomNumber
    );
  },

  JourneyTemplateView: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/journey/journey-template/view`,
      credential,
      token,
      randomNumber
    );
  },

  // adminservice
  LoginForm: (credential) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postWithoutTokenApi(
      `${dynamicKlassAdminUrl().adminUrl}/letme`,
      credential,
      randomNumber
    );
  },
  // common apis
  UserTypeList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/master/user-type/list`,
      credential,
      token,
      randomNumber
    );
  },
  DepartmentList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/master/department/list`,
      credential,
      token,
      randomNumber
    );
  },
  DepartmentLevelList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/master/department-level/list`,
      credential,
      token,
      randomNumber
    );
  },
  BranchList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/master/branch/list`,
      credential,
      token,
      randomNumber
    );
  },
  ReportingManager: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/master/reporting-manager/list`,
      credential,
      token,
      randomNumber
    );
  },
  DesignationList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/master/designation/list`,
      credential,
      token,
      randomNumber
    );
  },
  InternalSalesManager: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/master/internal-sales-manager/list`,
      credential,
      token,
      randomNumber
    );
  },
  OrganizationList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/master/partner-user-organization/list`,
      credential,
      token,
      randomNumber
    );
  },
  FetchCamReport: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/report/new-report-details`,
      credential,
      token,
      randomNumber
    );
  },

  // projuct list module
  ProductModule: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/product-module/list`,
      credential,
      token,
      randomNumber
    );
  },
  // Manage access apis
  ListOfAccessLevel: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/access-level/list`,
      credential,
      token,
      randomNumber
    );
  },
  ListOfReportAccessLevel: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/access-level-report/list`,
      credential,
      token,
      randomNumber
    );
  },
  ListOfAccessPagesAddEdit: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/access-level/view`,
      credential,
      token,
      randomNumber
    );
  },
  CreateAccessLevel: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/access-level/create`,
      credential,
      token,
      randomNumber
    );
  },
  AccessLevelDelete: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/access-level/delete`,
      credential,
      token,
      randomNumber
    );
  },
  UpdateAccessLevel: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/access-level/update`,
      credential,
      token,
      randomNumber
    );
  },
  ListOfKlassReportAccessPagesAddEdit: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/access-level-report/view`,
      credential,
      token,
      randomNumber
    );
  },
  CreateReportAccessLevel: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/access-level-report/create`,
      credential,
      token,
      randomNumber
    );
  },
  UpdateReportAccessLevel: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/access-level-report/update`,
      credential,
      token,
      randomNumber
    );
  },
  ReportAccessLevelDelete: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/access-level-report/delete`,
      credential,
      token,
      randomNumber
    );
  },
  //  super admin

  AdminOfAccessLevel: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/admin-access/list`,
      credential,
      token,
      randomNumber
    );
  },

  AdminOfAccessLevelDelete: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/admin-access/delete`,
      credential,
      token,
      randomNumber
    );
  },

  AdminOfAddAccessLevel: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/admin-access/view`,
      credential,
      token,
      randomNumber
    );
  },

  SuperAdminUpdateAccessLevel: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/admin-access/update`,
      credential,
      token,
      randomNumber
    );
  },
  SuperAdminCreateAccessLevel: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/admin-access/create`,
      credential,
      token,
      randomNumber
    );
  },

  AdminCreateUser: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/admin-user/create`,
      credential,
      token,
      randomNumber
    );
  },

  AdminUpdateUser: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/admin-user/update`,
      credential,
      token,
      randomNumber
    );
  },

  AdminViewUser: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/admin-user/view`,
      credential,
      token,
      randomNumber
    );
  },

  AdminDeleteUser: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/admin-user/delete`,
      credential,
      token,
      randomNumber
    );
  },

  // users api
  ListOfUsers: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/user/list`,
      credential,
      token,
      randomNumber
    );
  },
  AdminListOfUsers: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/admin-user/list`,
      credential,
      token,
      randomNumber
    );
  },
  AccessOfUsers: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/admin-access/configs`,
      credential,
      token,
      randomNumber
    );
  },
  UserPermission: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().adminUrl}/user-permission/${encryptValue(
        `${credential}`,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },
  CreateUser: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApiAdminUser(
      `${dynamicKlassAdminUrl().adminUrl}/user/create`,
      credential,
      token,
      randomNumber
    );
  },
  UpdateUser: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApiAdminUser(
      `${dynamicKlassAdminUrl().adminUrl}/user/update`,
      credential,
      token,
      randomNumber
    );
  },
  SaveSMTPData: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApiAdminUser(
      `${dynamicKlassAdminUrl().adminUrl}/email/update-config`,
      credential,
      token,
      randomNumber
    );
  },
  SaveSMTPEmail: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApiAdminUser(
      `${dynamicKlassAdminUrl().adminUrl}/email/test-mail`,
      credential,
      token,
      randomNumber
    );
  },
  SMTPFileds: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().adminUrl}/email/get-details/${encryptValue(
        `${credential}`,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },
  ViewUser: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/user/view`,
      credential,
      token,
      randomNumber
    );
  },

  DeleteUser: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/user/delete`,
      credential,
      token,
      randomNumber
    );
  },

  // policy service
  AddNewPolicy: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postpolicyApi(
      `${dynamicKlassAdminUrl().adminUrl}/policy/add-new-policy`,
      credential,
      token,
      randomNumber
    );
  },
  PoliciesList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.policylistApi(
      `${dynamicKlassAdminUrl().adminUrl}/policy/policies-list`,
      credential,
      token,
      randomNumber
    );
  },
  // policy view details
  PolicyViewDetails: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/policy/view-details`,
      credential,
      token,
      randomNumber
    );
  },
  AllEmpMastersList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/company/all-emp-masters/list`,
      credential,
      token,
      randomNumber
    );
  },

  // prospect service
  // List all propsect
  ProspectListApi: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/prospect/list`,
      credential,
      token,
      randomNumber
    );
  }, // TASK#6, TASK#7, TASK#9  // done api response pending

  // Fetch Prospect Status for filter
  ProspectListStageStatuses: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/prospect/list-stage-statuses`,
      credential,
      token,
      randomNumber
    );
  }, // TASK#8

  // View individual prospect
  ProspectView: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/prospect/view`,
      credential,
      token,
      randomNumber
    );
  }, // TASK#10

  // Delete prospect
  ProspectDelete: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/prospect/delete`,
      credential,
      token,
      randomNumber
    );
  }, // TASK#11

  // Bulk Upload the Prospect
  ProspectBulkUpload: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/prospect/bulk-upload`,
      credential,
      token,
      randomNumber
    );
  }, // TASK#12

  // Download "Bulk Upload" Template
  ProspectDownloadTemplate: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().adminUrl}/prospect/download-template`,
      token,
      randomNumber
    );
  }, // TASK#13

  // Save Prospect to database
  ProspectCreate: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/prospect/create`,
      credential,
      token,
      randomNumber
    );
  }, // TASK#5  // done api response pending

  // assign leads
  AssignProspects: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/prospect/assign-prospects`,
      credential,
      token,
      randomNumber
    );
  },

  // Employment Types
  EmploymentTypes: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().adminUrl}/master/all-combined-employment-types`,
      token,
      randomNumber
    );
  },

  // Property Configuration Type
  PropertyConfigurationType: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().adminUrl}/master/property-configuration-type`,
      token,
      randomNumber
    );
  },
  DownloadProspectTemplate: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.fireApiProspectDownload(
      `${dynamicKlassAdminUrl().adminUrl}/prospect/downloadCSVTemplate`,
      token,
      randomNumber
    );
  },
  ProspectUpload: (randomIv) => `${dynamicKlassAdminUrl().adminUrl}/prospect/upload`,
  AllLoanMastersList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/company/all-loan-masters/list`,
      credential,
      token,
      randomNumber
    );
  },

  CommonApiforDOCUCheckReports: async (
    method,
    endpoint,
    credentials,
    authToken,
    formType,
    reqParam
  ) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
    console.log('endpointlogcommonadmin', endpoint, reqParam);
    if (reqParam !== undefined && reqParam !== null && reqParam !== '') {
      endpoint = `${endpoint}${encryptValue(reqParam, randomIv)}`;
      console.log('endpointlogcommonaferadmin', endpoint, reqParam);
    }
    console.log('endpointlogcommonafer543543admin', endpoint, reqParam);
    try {
      const headerData = {};
      const requestBody = {};
      const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
      console.log('userDetails679788', userDetails, formType);
      const userId = userDetails?.token?.userId;
      const currentDate = new Date();
      const formatter = new Intl.DateTimeFormat('en-IN', options);
      const timestamp = Date.now();
      const nonBinaryData = {};
      const binaryFormData = new FormData();
      if (formType === 'formdata') {
        for (const [key, value] of credentials.entries()) {
          // Check if the value is binary data
          if (value instanceof File || value instanceof Blob) {
            // If binary data, append it to binaryFormData
            binaryFormData.append('document1', value);
          } else {
            // Encrypt non-binary data
            // const encryptedValue = CryptoJS.AES.encrypt(value.toString(), secretKey).toString();
            // Store encrypted value in encryptedNonBinaryData object
            nonBinaryData[key] = value;
          }
        }
        headerData.Authorization = `Bearer ${authToken}`;
        headerData['client-id'] = getClientId();
        headerData['payload-data'] = encryptPayload(nonBinaryData, randomIv);
        headerData['request-id'] = `${encryptValue(
          `${timestamp}_${userId}_${randomNumber}_`,
          randomIv
        )}${randomIv}`;
      } else {
        headerData['Content-Type'] = `application/json`;
        headerData.Authorization = `Bearer ${authToken}`;
        headerData['client-id'] = getClientId();
        headerData['request-id'] = `${encryptValue(
          `${timestamp}_${userId}_${randomNumber}_`,
          randomIv
        )}${randomIv}`;
      }

      if (method === 'GET' || method === 'DELETE') {
        requestBody.method = method;
        requestBody.headers = headerData;
      } else {
        let payload = credentials;
        if (isSerializedObject(credentials) === true) {
          payload = JSON.parse(credentials);
        } else {
          payload = formType === 'formdata' ? binaryFormData : JSON.parse(credentials);
        }
        requestBody.method = method;
        requestBody.headers = headerData;

        requestBody.body =
          formType === 'formdata'
            ? payload
            : Array.isArray(payload)
            ? encryptArrayOfObjects(payload, randomIv)
            : encryptPayload(payload, randomIv);
      }

      return await fetch(`${dynamicKlassAdminUrl().adminUrl}/${endpoint}`, requestBody)
        .then((response) => response.json())
        .then((res) => res);
    } catch (error) {
      return {
        msg: `Server not responding error ${error}`
      };
    }
  },

  // admin cards api starts

  CardFieldList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/master/card-field/list`,
      credential,
      token,
      randomNumber
    );
  },
  CardsList: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/master/card/list`,
      credential,
      token,
      randomNumber
    );
  },

  SecurityGridList: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().adminUrl}/manage-doc/security-doc-list`,
      token,
      randomNumber
    );
  },

  FetchSecurityDetails: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/security-docs/view`,
      credential,
      token,
      randomNumber
    );
  },
  ViewConditionMapDocument: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/security-docs-condition/view-documents`,
      credential,
      token,
      randomNumber
    );
  },
  DeleteSecurityDetails: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/security-docs/delete`,
      credential,
      token,
      randomNumber
    );
  },

  FetchSecurityDocsCondition: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/security-docs-condition/view`,
      credential,
      token,
      randomNumber
    );
  },

  FetchSecurityDocsCreate: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/security-docs-condition/create`,
      credential,
      token,
      randomNumber
    );
  },

  SaveSecurityLoanDetails: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/security-docs/create`,
      credential,
      token,
      randomNumber
    );
  },

  CardFieldMapp: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/master/card-field-mapp`,
      credential,
      token,
      randomNumber
    );
  },

  AddNewField: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApiAdminUser(
      `${dynamicKlassAdminUrl().adminUrl}/master/add-new-field`,
      credential,
      token,
      randomNumber
    );
  },

  FieldDataTypes: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().adminUrl}/master/field/data-types`,
      token,
      randomNumber
    );
  },

  FieldDetailsById: (token, fieldId) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().adminUrl}/master/field/${encryptValue(fieldId, randomNumber)}`,
      token,
      randomNumber
    );
  },

  FetchCardDetailsById: (token, cardMasterId) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().adminUrl}/master/fetch-card-details/${encryptValue(
        cardMasterId,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  FieldStatusUpdateById: (token, fieldId) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi1(
      `${dynamicKlassAdminUrl().adminUrl}/master/field/${encryptValue(fieldId, randomNumber)}`,
      token,
      randomNumber
    );
  },

  DeleteFieldDetailsById: (token, fieldId) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().adminUrl}/master/delete-field/${encryptValue(
        fieldId,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  EditField: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.putApi(
      `${dynamicKlassAdminUrl().adminUrl}/master/update-field`,
      credential,
      token,
      randomNumber
    );
  },

  FetchCardConfigs: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().adminUrl}/master/fetch-card-configs`,
      token,
      randomNumber
    );
  },

  AddNewCardData: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApiAdminUser(
      `${dynamicKlassAdminUrl().adminUrl}/master/add-new-card`,
      credential,
      token,
      randomNumber
    );
  },

  UpdateCardData: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/master/update-card`,
      credential,
      token,
      randomNumber
    );
  },

  // admin journey configure api services starts

  FetchValidationList: (token, journeyId) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${
        dynamicKlassAdminUrl().adminUrl
      }/journey/fetch-wizard-card-field-validations?${encryptValue(
        `journeyId=
        ${journeyId}`,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  FetchFunctionDetails: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().adminUrl}/journey/fetch-function-details`,
      token,
      randomNumber
    );
  },

  AddEditValidationJourneyConfigure: (credential, token, isEdit) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApiAdminUser(
      `${dynamicKlassAdminUrl().adminUrl}/journey/save-field-validation?${encryptValue(
        `isEdit=${isEdit}`,
        randomNumber
      )}`,
      credential,
      token,
      randomNumber
    );
  },

  CardStatusUpdateById: (token, cardId) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi1(
      `${dynamicKlassAdminUrl().adminUrl}/master/card/toggle-status/${encryptValue(
        cardId,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  ValidationStatusUpdateById: (token, validationId) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi1(
      `${dynamicKlassAdminUrl().adminUrl}/journey/toggle-validation-status/${encryptValue(
        validationId,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  DeleteValidationById: (token, validationId) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi1(
      `${dynamicKlassAdminUrl().adminUrl}/journey/delete-field-validation/${encryptValue(
        validationId,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  StatusUpdateById: (token, cardId) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi1(
      `${dynamicKlassAdminUrl().adminUrl}/master/card/toggle-status/${encryptValue(
        cardId,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  ConfigureDynamicMap: (credential, token, id) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/journey/configure/dynamic/map/${encryptValue(
        id,
        randomNumber
      )}`,
      credential,
      token,
      randomNumber
    );
  },

  DynamicStatus: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi2(
      `${dynamicKlassAdminUrl().adminUrl}/journey/configure/dynamic/status/${encryptValue(
        id,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },

  DynamicDependency: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/journey/configure/dynamic/dependency`,
      credential,
      token,
      randomNumber
    );
  },

  DeleteDependency: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/journey/configure/dynamic/remove`,
      credential,
      token,
      randomNumber
    );
  },

  DependencyReview: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().adminUrl}/journey/configure/dynamic/dependency-review`,
      credential,
      token,
      randomNumber
    );
  },

  JourneyConfigData: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/journey/config-data`,
      credential,
      token,
      randomNumber
    );
  },

  FetchAllSecurityDocuments: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().adminUrl}/master/security-doc-list`,
      token,
      randomNumber
    );
  }
};

// admin api ends

// api for forgot password
export const forgotPasswordService = {
  EmailGenerateOtp: (credential, type) => {
    const endPoint = type === 'Klass' ? '/login-otp-generate' : '/admin-login-otp-generate';
    const url =
      type === 'Klass' ? dynamicKlassAdminUrl().klassUrl : dynamicKlassAdminUrl().adminUrl;
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postWithoutTokenApi(`${url}${endPoint}`, credential, randomNumber);
  },
  EmailVerifyOtp: (credential) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postWithoutTokenApi(
      `${dynamicKlassAdminUrl().klassUrl}/email/verify-otp`,
      credential,
      randomNumber
    );
  },
  EmailVerifyOtpAdmin: (credential) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postWithoutTokenApi(
      `${dynamicKlassAdminUrl().adminUrl}/verify-otp`,
      credential,
      randomNumber
    );
  },
  EmailChangePassword: (credential1) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    console.log(randomNumber, 'line no 3986');
    return useApiServices.postWithoutTokenApi(
      `${dynamicKlassAdminUrl().klassUrl}/email/changePassword`,
      credential1,
      randomNumber
    );
  }
};

// credit appraisal api
const endpoint1 = 'fetch-credit-appraisal-v2';

export const creditAppraisalService = {
  FetchBalanceSheetDropdown: (id, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${
        dynamicKlassAdminUrl().klassUrl
      }/credit-appraisal/fetch-heading/${id}/balance_sheet_analysis_output`,
      token,
      randomNumber
    );
  },
  FetchCreditAppraisal: (id, credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/${endpoint1}`,
      credential,
      token,
      randomNumber
    );
  },

  DownloadExcel: (id, credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi4(
      `${dynamicKlassAdminUrl().klassUrl}/credit-appraisal/export-pdf`,
      credential,
      token,
      randomNumber
    );
  },

  FetchRegenerateCamStatus: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.putApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-appraisal/recalculate-status`,
      credential,
      token,
      randomNumber
    );
  },

  DeleteRecord: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.putApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-appraisal/delete`,
      credential,
      token,
      randomNumber
    );
  },

  SaveColumnData: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.putApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-appraisal/add-heading`,
      credential,
      token,
      randomNumber
    );
  },

  CreditAppraisalFilter: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.putApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-appraisal/filter`,
      credential,
      token,
      randomNumber
    );
  },

  RatingFilter: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.putApi(
      `${dynamicKlassAdminUrl().klassUrl}/rating/filter`,
      credential,
      token,
      randomNumber
    );
  },

  AddNewRecord: (credential, token, actionValue) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-appraisal/save`,
      credential,
      token,
      randomNumber
    );
  },

  AddNewRecordSubmit: (credential, token, actionValue) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.putApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-appraisal/save-and-recalculate`,
      credential,
      token,
      randomNumber
    );
  },

  CreditAppraisalRegenerateCam: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-appraisal/re-generate`,
      credential,
      token,
      randomNumber
    );
  },

  CreditTaskInRegenrateCam: (applicationId, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-task/sync-deviation-credit-task/${applicationId}`,
      token,
      randomNumber
    );
  },

  CreditAppraisalSelect: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/credit-appraisal/select`,
      credential,
      token,
      randomNumber
    );
  },
  SaveAndEditCardData: (credential, token, actionValue) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.putApi(
      actionValue
        ? `${dynamicKlassAdminUrl().klassUrl}/credit-appraisal/update-and-recalculate`
        : `${dynamicKlassAdminUrl().klassUrl}/credit-appraisal/update`,
      credential,
      token,
      randomNumber
    );
  }
};

export const AssignServices = {
  FetchUserData: (applicationId, token, mappIds) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi1(
      `${dynamicKlassAdminUrl().klassUrl}/fetch-user-data/${encryptValue(
        applicationId,
        randomNumber
      )}`,
      token,
      mappIds,
      randomNumber
    );
  },
  FetchUser: (branchId, token, mappIds) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi1(
      `${dynamicKlassAdminUrl().klassUrl}/fetch-user/${encryptValue(branchId, randomNumber)}`,
      token,
      mappIds,
      randomNumber
    );
  },
  FetchBranchList: (token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi(
      `${dynamicKlassAdminUrl().klassUrl}/branch-list`,
      token,
      randomNumber
    );
  },
  FetchBranchInfo: (applicationId, token, mappIds) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.getApi1(
      `${dynamicKlassAdminUrl().klassUrl}/fetch-branch-info/${encryptValue(
        applicationId,
        randomNumber
      )}`,
      token,
      mappIds,
      randomNumber
    );
  },
  FetchOwnerInfo: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/fetch-owner-data`,
      credential,
      token,
      randomNumber
    );
  },
  UpdateUser: (token, applicationId, toUserId) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.putApi1(
      `${dynamicKlassAdminUrl().klassUrl}/update-assigned-user/${encryptValue(
        `${applicationId}/${toUserId}`,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },
  UpdateBranch: (token, applicationId, toBranchId) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.putApi1(
      `${dynamicKlassAdminUrl().klassUrl}/update-assigned-branch/${encryptValue(
        `${applicationId}/${toBranchId}`,
        randomNumber
      )}`,
      token,
      randomNumber
    );
  },
  UpdateOwner: (credential, token) => {
    const min = 1000;
    const max = 9999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return useApiServices.postApi(
      `${dynamicKlassAdminUrl().klassUrl}/update-lead-owner`,
      credential,
      token,
      randomNumber
    );
  }
};

async function fireLoginApi(endpoint, credentials, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  console.log('clientIdclientId', getClientId());
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Device-Browser': deviceInfo.browser,
      'Device-OS': deviceInfo.os,
      'Device-Model': deviceInfo.device,
      'Content-Type': 'application/json',
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    },
    body: encryptPayload(credentials, randomIv)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 403) {
        const apiErrorPop = setTimeout(() => {
          sessionStorage.clear();
          redirectToLoginPage();
        }, 3000);
        clearTimeout(apiErrorPop);
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}
async function firePostTokenApi(endpoint, token, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    }
  })
    .then((response) => {
      console.log('response', response);
      if (response.ok) {
        return response.json();
      } else if (response.status === 403) {
        sessionStorage.clear();
        redirectToLoginPage();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function firePostTokenApiLogo(endpoint, token, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    }
  })
    .then((response) => {
      console.log('response', response);
      if (response.ok) {
        return response.json();
      } else if (response.status === 403) {
        sessionStorage.clear();
        redirectToLoginPage();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

const redirectToLoginPage = () => {
  store.dispatch({
    type: 'LOGIN_INFO',
    payload: { data: {} }
  });
  const loginUrl = '/';
  const anchor = document.createElement('a');
  anchor.href = loginUrl;
  anchor.click();
};

async function fireApi(endpoint, credentials, authToken, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  console.log(getClientId(), 'line no 1051');
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    },
    body: encryptPayload(credentials, randomIv)
  })
    .then((response) => {
      if (response.ok) {
        if (
          response.headers.get('content-type').includes('application/octet-stream') ||
          response.headers.get('content-type').includes('multipart/form-data')
        ) {
          return response.blob();
        }
        return response.json();
      } else if (response.status === 403) {
        setTimeout(() => {
          sessionStorage.clear();
          redirectToLoginPage();
        }, 5000);
      }
      console.log('responseinpostapifire', response);
      return response.json();
    })
    .catch((error) => {
      console.log('errorinapifire', error);
      if (error === 'Forbidden') {
        sessionStorage.clear();
        sessionStorage.clear();
      }
      return {
        status: 'error',
        msg: 'Server not responding',
        data: [],
        endpoint: endpoint
      };
    });
}
async function fireApiForExternalApi(endpoint, credentials, authToken, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  console.log(getClientId(), 'line no 1051');
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`

      // 'request-id': `${encryptValue(
      //   `${timestamp}_${userId}_${randomNumber}_`,
      //   randomIv
      // )}${randomIv}`
    },
    body: JSON.stringify(credentials)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 403) {
        setTimeout(() => {
          sessionStorage.clear();
          redirectToLoginPage();
        }, 5000);
      }
      console.log('responseinpostapifire', response);
      return response.json();
    })
    .catch((error) => {
      console.log('errorinapifire', error);
      if (error === 'Forbidden') {
        sessionStorage.clear();
        sessionStorage.clear();
      }
      return {
        status: 'error',
        msg: 'Server not responding',
        data: [],
        endpoint: endpoint
      };
    });
}

async function fireApi3(endpoint, credentials, authToken, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  console.log(getClientId(), 'line no 1051');
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    },
    body: encryptPayload(credentials, randomIv)
  })
    .then(async (response) => {
      if (response.ok) {
        const blob = await response.blob();
        return { status: 'success', blob: blob };
      } else if (response.status === 403) {
        setTimeout(() => {
          sessionStorage.clear();
          redirectToLoginPage();
        }, 5000);
      }
      console.log('responseinpostapifire', response);
      const blob = await response.blob();
      return { status: 'success', blob: blob };
    })
    .catch((error) => {
      console.log('errorinapifire', error);
      if (error === 'Forbidden') {
        sessionStorage.clear();
        sessionStorage.clear();
      }
      return {
        status: 'error',
        msg: 'Server not responding',
        data: [],
        endpoint: endpoint
      };
    });
}

async function fireApi2(endpoint, credentials, authToken, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  console.log(getClientId(), 'line no 1051');
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    },
    body: encryptArrayOfObjects(credentials, randomIv)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 403) {
        sessionStorage.clear();
        redirectToLoginPage();
      }
      console.log('responseinpostapifire', response);
      return response.json();
    })
    .catch((error) => {
      console.log('errorinapifire', error);
      if (error === 'Forbidden') {
        sessionStorage.clear();
        sessionStorage.clear();
      }
      return {
        status: 'error',
        msg: 'Server not responding',
        data: [],
        endpoint: endpoint
      };
    });
}

async function fireApiBlob(endpoint, credentials, authToken, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  console.log(getClientId(), 'line no 1051');
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    },
    body: encryptArrayOfObjects(credentials, randomIv)
  })
    .then((response) => {
      if (response.ok) {
        return response;
      } else if (response.status === 403) {
        sessionStorage.clear();
        redirectToLoginPage();
      }
      console.log('responseinpostapifire', response);
      return response;
    })
    .catch((error) => {
      console.log('errorinapifire', error);
      if (error === 'Forbidden') {
        sessionStorage.clear();
        sessionStorage.clear();
      }
      return {
        status: 'error',
        msg: 'Server not responding',
        data: [],
        endpoint: endpoint
      };
    });
}

async function fireApi1(endpoint, authToken, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  console.log(getClientId(), 'line no 1051');
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    }
  })
    .then((response) => {
      if (response.ok) {
        console.log('responseinpostapi', response);
        return response.json();
      } else if (response.status === 403) {
        sessionStorage.clear();
        redirectToLoginPage();
      }
      return response.json();
    })
    .catch((error) => {
      console.log('errorinapi', error);
      if (error === 'Forbidden') {
        sessionStorage.clear();
      }
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function fireApiPost(endpoint, authToken, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  console.log(clientId, 'line no 1051');
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    }
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 403) {
        sessionStorage.clear();
        redirectToLoginPage();
      }
      return {
        status: response.status,
        msg: response.msg,
        data: response.json()
      };
    })
    .catch((error) => {
      console.log(error);
      if (error === 'Forbidden') {
        sessionStorage.clear();
      }
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function fireApiAdminUser(endpoint, credentials, authToken, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    },
    body: encryptPayload(credentials, randomIv)
  })
    .then((response) => {
      console.log('response', response);
      if (response.ok) {
        return response.json();
      } else if (response.status === 403) {
        sessionStorage.clear();
        redirectToLoginPage();
      }
      return response.json();
      // return {
      //   status: response.status,
      //   msg: response.msg,
      //   data: response.json()
      // };
    })
    .catch((error) => {
      console.log('response-error', error);
      if (error === 'Forbidden') {
        sessionStorage.clear();
        sessionStorage.clear();
      }
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function fireApiRevenueDownloadFile(endpoint, credentials, authToken, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      accept: 'text/csv',
      Authorization: `Bearer ${authToken}`,
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    },
    body: encryptPayload(credentials, randomIv)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 403) {
        sessionStorage.clear();
        redirectToLoginPage();
      }
      return {
        status: response.status,
        msg: response.msg,
        data: response.json()
      };
    })
    .catch((error) => {
      console.log(error);
      if (error === 'Forbidden') {
        sessionStorage.clear();
        sessionStorage.clear();
      }
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function fireApiProspectDownloadFile(endpoint, authToken, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  return fetch(endpoint, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    }
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else if (response.status === 403) {
      sessionStorage.clear();
      redirectToLoginPage();
    }
  });
}

async function fireviewagreementApi(endpoint, credentials, authToken, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    },
    body: encryptPayload(credentials, randomIv)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 403) {
        sessionStorage.clear();
        redirectToLoginPage();
      }
      return {
        status: response.status,
        msg: response.msg,
        data: response.json()
      };
    })
    .catch((error) => {
      console.log(error);
      if (error === 'Forbidden') {
        sessionStorage.clear();
        sessionStorage.clear();
      }
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function firepolicyApi(endpoint, credentials, authToken, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    },
    body: encryptPayload(credentials, randomIv)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 403) {
        sessionStorage.clear();
        redirectToLoginPage();
      }
      return {
        status: response.status,
        msg: response.msg,
        data: response.json()
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function firepolicylistApi(endpoint, credentials, authToken, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    },
    body: encryptPayload(credentials, randomIv)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 403) {
        sessionStorage.clear();
        redirectToLoginPage();
      }
      return {
        status: response.status,
        msg: response.msg,
        data: response.json()
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function fireagreementlistApi(endpoint, credentials, authToken, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    },
    body: encryptPayload(credentials, randomIv)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 403) {
        sessionStorage.clear();
        redirectToLoginPage();
      }
      return {
        status: response.status,
        msg: response.msg,
        data: response.json()
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function restartsignupfireApi(endpoint, credentials, authToken, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    },
    body: encryptPayload(credentials, randomIv)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 403) {
        sessionStorage.clear();
        redirectToLoginPage();
      }
      return {
        status: response.status,
        msg: response.msg,
        data: response.json()
      };
    })
    .catch((error) => {
      console.log(error);
      if (error === 'Forbidden') {
        sessionStorage.clear();
        sessionStorage.clear();
      }
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function postFileApi(endpoint, credentials, authToken, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    },
    body: encryptPayload(credentials, randomIv)
  })
    .then((response) => {
      console.log(response);

      if (response.ok) {
        return response.json();
      } else if (response.status === 403) {
        sessionStorage.clear();
        redirectToLoginPage();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}
async function putToApi(endpoint, credentials, authToken, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  return fetch(endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    },
    body: encryptPayload(credentials, randomIv)
  })
    .then((response) => {
      console.log(response);
      if (response.ok) {
        return response.json();
      } else if (response.status === 403) {
        sessionStorage.clear();
        redirectToLoginPage();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function putToApi1(endpoint, authToken, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  return fetch(endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    }
  })
    .then((response) => {
      console.log(response);
      if (response.ok) {
        return response.json();
      } else if (response.status === 403) {
        sessionStorage.clear();
        redirectToLoginPage();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function putToApi2(endpoint, authToken, applicationId, imageUrl, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  return fetch(endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      imageURL: imageUrl,
      applicationId: applicationId,
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    }
  })
    .then((response) => {
      console.log(response);
      if (response.ok) {
        return response.json();
      } else if (response.status === 403) {
        sessionStorage.clear();
        redirectToLoginPage();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function putToApi3(endpoint, authToken, imageUrl) {
  return fetch(endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      imageURL: imageUrl,
      'client-id': getClientId()
    }
  })
    .then((response) => {
      console.log(response);
      if (response.ok) {
        return response.json();
      } else if (response.status === 403) {
        sessionStorage.clear();
        redirectToLoginPage();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function putToApi4(endpoint, authToken, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  return fetch(endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    }
  })
    .then((response) => {
      console.log(response);
      if (response.ok) {
        return response.json();
      } else if (response.status === 403) {
        console.log('response', response);
        // sessionStorage.clear();
        // redirectToLoginPage();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function deleteToApi(endpoint, authToken, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788543543', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  return fetch(endpoint, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    }
  })
    .then((response) => {
      console.log('responsedelete', response);

      if (response.ok) {
        return response.json();
      } else if (response.status === 403) {
        sessionStorage.clear();
        redirectToLoginPage();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log('errorresponse', error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}
async function getToApi(endpoint, authToken, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  return fetch(endpoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      'client-id': getClientId(),
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    }
  })
    .then((response) => {
      console.log(response);

      if (response.ok) {
        if (
          response.headers.get('content-type').includes('application/octet-stream') ||
          response.headers.get('content-type').includes('application/zip') ||
          response.headers.get('content-type').includes('application/pdf') ||
          response.headers.get('content-type').includes('application/vnd.ms-excel')
        ) {
          return response.blob();
        }
        return response.json();
      } else if (response.status === 403) {
        sessionStorage.clear();
        redirectToLoginPage();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function getToApiWithoutMapId(endpoint, authToken, mappIds) {
  return fetch(endpoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      'client-id': clientId
    }
  })
    .then((response) => {
      console.log(response);

      if (response.ok) {
        return response.json();
      } else if (response.status === 403) {
        sessionStorage.clear();
        redirectToLoginPage();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function getToApiWithoutRandomNumber(endpoint, authToken, mappIds) {
  return fetch(endpoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      'client-id': clientId,
      'Mapp-Ids': mappIds
    }
  })
    .then((response) => {
      console.log(response);

      if (response.ok) {
        return response.json();
      } else if (response.status === 403) {
        sessionStorage.clear();
        redirectToLoginPage();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

async function getToApi1(endpoint, authToken, mappIds, randomIv) {
  const userDetails = await JSON.parse(sessionStorage.getItem('userDetails'));
  console.log('userDetails679788', userDetails);
  const userId = userDetails?.token?.userId;
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat('en-IN', options);
  const timestamp = Date.now();
  const min = 1000;
  const max = 9999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  // const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
  return fetch(endpoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      'client-id': getClientId(),
      'Mapp-Ids': mappIds,
      'request-id': `${encryptValue(
        `${timestamp}_${userId}_${randomNumber}_`,
        randomIv
      )}${randomIv}`
    }
  })
    .then((response) => {
      console.log(response);

      if (response.ok) {
        return response.json();
      } else if (response.status === 403) {
        sessionStorage.clear();
        redirectToLoginPage();
      }
      return {
        status: 'error',
        msg: statusMessages[response.status],
        data: []
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: 'error',
        msg: 'Server not responding',
        data: []
      };
    });
}

export const useApiServices = {
  postWithoutTokenApi: async (endPoint, credential, randomNumber) => {
    const isDataPosted = await fireLoginApi(endPoint, credential, randomNumber);
    return isDataPosted;
  },
  postTokenApi: async (endPoint, token, randomNumber) => {
    const isDataPosted = await firePostTokenApi(endPoint, token, randomNumber);
    return isDataPosted;
  },
  postTokenApiLogo: async (endPoint, token, randomNumber) => {
    const isDataPosted = await firePostTokenApiLogo(endPoint, token, randomNumber);
    return isDataPosted;
  },
  postApi: async (endPoint, credential, authToken, randomNumber) => {
    const isDataPosted = await fireApi(endPoint, credential, authToken, randomNumber);
    return isDataPosted;
  },
  postApiExternalApi: async (endPoint, credential, authToken, randomNumber) => {
    const isDataPosted = await fireApiForExternalApi(endPoint, credential, authToken, randomNumber);
    return isDataPosted;
  },
  postApi4: async (endPoint, credential, authToken, randomNumber) => {
    const isDataPosted = await fireApi3(endPoint, credential, authToken, randomNumber);
    return isDataPosted;
  },
  postApi1: async (endPoint, authToken, randomNumber) => {
    const isDataPosted = await fireApi1(endPoint, authToken, randomNumber);
    return isDataPosted;
  },
  postApiBlob: async (endPoint, credential, authToken, randomNumber) => {
    const isDataPosted = await fireApiBlob(endPoint, credential, authToken, randomNumber);
    return isDataPosted;
  },
  postApi2: async (endPoint, authToken, randomNumber) => {
    const isDataPosted = await fireApiPost(endPoint, authToken, randomNumber);
    return isDataPosted;
  },
  postApi3: async (endPoint, credential, authToken, randomNumber) => {
    const isDataPosted = await fireApi2(endPoint, credential, authToken, randomNumber);
    return isDataPosted;
  },
  postApiAdminUser: async (endPoint, credential, authToken, randomNumber) => {
    const isDataPosted = await fireApiAdminUser(endPoint, credential, authToken, randomNumber);
    return isDataPosted;
  },
  fireApiRevenueDownloadFilePostApi: async (endPoint, credential, authToken, randomNumber) => {
    const isDataPosted = await fireApiRevenueDownloadFile(
      endPoint,
      credential,
      authToken,
      randomNumber
    );
    return isDataPosted;
  },
  fireApiProspectDownload: async (endPoint, authToken, randomNumber) => {
    const isDataPosted = await fireApiProspectDownloadFile(endPoint, authToken, randomNumber);
    return isDataPosted;
  },
  viewagreementpostApi: async (endPoint, credential, authToken, randomNumber) => {
    const isDataPosted = await fireviewagreementApi(endPoint, credential, authToken, randomNumber);
    return isDataPosted;
  },
  postpolicyApi: async (endPoint, credential, authToken, randomNumber) => {
    const isDataPosted = await firepolicyApi(endPoint, credential, authToken, randomNumber);
    return isDataPosted;
  },
  policylistApi: async (endPoint, credential, authToken, randomNumber) => {
    const isDataPosted = await firepolicylistApi(endPoint, credential, authToken, randomNumber);
    return isDataPosted;
  },
  agreementlistApi: async (endPoint, credential, authToken, randomNumber) => {
    const isDataPosted = await fireagreementlistApi(endPoint, credential, authToken, randomNumber);
    return isDataPosted;
  },
  restartPartnerSignupWithTokenApi: async (endPoint, credential, authToken, randomNumber) => {
    const isDataPosted = await restartsignupfireApi(endPoint, credential, authToken, randomNumber);
    return isDataPosted;
  },

  postFileData: async (endPoint, credential, authToken, randomNumber) => {
    const isDataPosted = await postFileApi(endPoint, credential, authToken, randomNumber);
    return isDataPosted;
  },

  putApi: async (endPoint, credential, authToken, randomNumber) => {
    const isDataPosted = await putToApi(endPoint, credential, authToken, randomNumber);
    return isDataPosted;
  },

  putApi1: async (endPoint, authToken, randomNumber) => {
    const isDataPosted = await putToApi1(endPoint, authToken, randomNumber);
    return isDataPosted;
  },
  putApi2: async (endPoint, authToken, applicationId, imageUrl, randomNumber) => {
    const isDataPosted = await putToApi2(
      endPoint,
      authToken,
      applicationId,
      imageUrl,
      randomNumber
    );
    return isDataPosted;
  },
  putApi3: async (endPoint, authToken, imageUrl) => {
    const isDataPosted = await putToApi3(endPoint, authToken, imageUrl);
    return isDataPosted;
  },
  putApi4: async (endPoint, credential, authToken, randomNumber) => {
    const isDataPosted = await putToApi4(endPoint, credential, authToken, randomNumber);
    return isDataPosted;
  },

  getApi: async (endPoint, authToken, randomNumber) => {
    const isDataPosted = await getToApi(endPoint, authToken, randomNumber);
    return isDataPosted;
  },
  getApiWithoutMapId: async (endPoint, credential, authToken) => {
    const isDataPosted = await getToApiWithoutMapId(endPoint, credential, authToken);
    return isDataPosted;
  },

  getApiWithoutRandomNumber: async (endPoint, credential, authToken) => {
    const isDataPosted = await getToApiWithoutRandomNumber(endPoint, credential, authToken);
    return isDataPosted;
  },

  getApi1: async (endPoint, credential, authToken, randomNumber) => {
    const isDataPosted = await getToApi1(endPoint, credential, authToken, randomNumber);
    return isDataPosted;
  },

  deleteApi: async (endPoint, authToken, randomNumber) => {
    const isDataPosted = await deleteToApi(endPoint, authToken, randomNumber);
    return isDataPosted;
  }
};
